import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import Loader from "./Components/Loader";
const EditProduct = lazy(() => import("./pages/EditProduct"));
const Home = lazy(() => import("./pages/Home"));
const ProductList = lazy(() => import("./pages/ProductList"));
const ListingsList = lazy(() => import("./pages/ListingsList"));
const RentalList = lazy(() => import("./pages/RentalList"));
const Register = lazy(() => import("./pages/Register"));
const Login = lazy(() => import("./pages/Login"));
const Cart = lazy(() => import("./pages/Cart"));
const Success = lazy(() => import("./pages/Success"));
const Profile = lazy(() => import("./pages/Profile"));
const SearchResults = lazy(() => import("./pages/SearchResults"));
const Messenger = lazy(() => import("./pages/Messenger/Messenger"));
const EditProfile = lazy(() => import("./pages/EditProfile"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const VerifyEmail = lazy(() => import("./pages/VerifyEmail"));
const SetupPayouts = lazy(() => import("./pages/SetupPayouts"));
const SetupSuccess = lazy(() => import("./pages/SetupSuccess"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Terms = lazy(() => import("./pages/Terms"));
const FAQIdentity = lazy(() => import("./pages/FAQIdentity"));
const SupportTicket = lazy(() => import("./pages/SupportTicket"));
const NotFound = lazy(() => import("./pages/NotFound"));
const ProductUpload = lazy(() => import("./pages/ProductUpload"));
const ProductUploaded = lazy(() => import("./pages/ProductUploaded"));
const OrderList = lazy(() => import("./pages/OrderList"));
const VerifyIDComplete = lazy(() => import("./pages/VerifyIDComplete"));
const VerifyIDStart = lazy(() => import("./pages/VerifyIDStart"));
const VerifyIDPay = lazy(() => import("./pages/VerifyIDPay"));
const LenderGuarantee = lazy(() => import("./pages/LenderGuarantee"));
const FAQs = lazy(() => import("./pages/FAQs"));
const Product = lazy(() => import("./pages/Product"));
const BooksSubCatResults = lazy(() => import("./pages/BooksSubCatResults"));
const BooksMenu = lazy(() => import("./pages/BooksMenu"));
const ReturnAddress = lazy(() => import("./pages/ReturnAddress"));
const AllCategories = lazy(() => import("./pages/AllCategories"));
const RegistrationSuccessful = lazy(() =>
  import("./pages/RegistrationSuccessful")
);

const App = () => {
  var tokenNotExpired = null;
  var accessPermitted = null;
  const user = useSelector((state) => state.user.currentUser);
  if (user?.accessToken) {
    var decoded = jwt_decode(user.accessToken);
    const tokenDate = new Date(decoded.exp * 1000);
    tokenNotExpired = !(new Date() - tokenDate > 0);
    accessPermitted = user && tokenNotExpired;
  } else {
    accessPermitted = false;
  }
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/products/books/:subcat">
            <BooksSubCatResults />
          </Route>
          <Route path="/products/book-genres">
            <BooksMenu />
          </Route>
          <Route path="/products/:categories">
            <ProductList />
          </Route>
          <Route path="/loading">
            <Loader />
          </Route>
          <Route path="/product/edit/:id">
            <EditProduct />
          </Route>
          <Route path="/product/:id">
            <Product />
          </Route>

          <Route path="/search/:phrase">
            <SearchResults />
          </Route>
          <Route path="/cart">
            <Cart />
          </Route>
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/profile/edit/:id">
            <EditProfile />
          </Route>
          <Route path="/profile/:id">
            <Profile />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/faq-identity">
            <FAQIdentity />
          </Route>
          <Route path="/faqs">
            <FAQs />
          </Route>
          <Route path="/support-ticket">
            <SupportTicket />
          </Route>
          <Route path="/lender-guarantee">
            <LenderGuarantee />
          </Route>
          <Route path="/all-categories">
            <AllCategories />
          </Route>
          <Route path="/login">
            {accessPermitted ? <Redirect to="/" /> : <Login />}
          </Route>
          <Route path="/register">
            {accessPermitted ? <Redirect to="/" /> : <Register />}
          </Route>
          <Route path="/registration-successful">
            {accessPermitted && <RegistrationSuccessful />}
          </Route>
          <Route path="/upload">
            {!accessPermitted ? <Redirect to="/login" /> : <ProductUpload />}
          </Route>
          <Route path="/myrentals">
            {!accessPermitted ? <Redirect to="/login" /> : <OrderList />}
          </Route>
          <Route path="/returnaddress">
            {!accessPermitted ? <Redirect to="/login" /> : <ReturnAddress />}
          </Route>
          <Route path="/mylistings">
            {!accessPermitted ? <Redirect to="/login" /> : <ListingsList />}
          </Route>
          <Route path="/myrentalorders">
            {!accessPermitted ? <Redirect to="/login" /> : <RentalList />}
          </Route>
          <Route path="/messenger">
            {!accessPermitted ? <Redirect to="/" /> : <Messenger />}
          </Route>
          <Route path="/verify-email">
            <VerifyEmail />
          </Route>
          <Route path="/forgot-password">
            {accessPermitted ? <Redirect to="/" /> : <ForgotPassword />}
          </Route>
          <Route path="/listing-successful/:id">
            {!accessPermitted ? (
              <Redirect to="/product/:id" />
            ) : (
              <ProductUploaded />
            )}
          </Route>
          <Route path="/reset-password">
            {accessPermitted ? <Redirect to="/" /> : <ResetPassword />}
          </Route>
          <Route path="/verification-submitted">
            {!accessPermitted ? <Redirect to="/" /> : <VerifyIDComplete />}
          </Route>
          <Route path="/verification-begin">
            {!accessPermitted ? (
              <Redirect to="/login?redirect=verification-begin" />
            ) : (
              <VerifyIDPay />
            )}
          </Route>
          <Route path="/verification-start">
            {!accessPermitted ? (
              <Redirect to="/login?redirect=verification-start" />
            ) : (
              <VerifyIDStart />
            )}
          </Route>
          <Route path="/setup-payouts">
            {!accessPermitted ? <Redirect to="/" /> : <SetupPayouts />}
          </Route>
          <Route path="/setup-success">
            {!accessPermitted ? <Redirect to="/" /> : <SetupSuccess />}
          </Route>
          <Route path="*" status="404">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
