import { createSlice } from "@reduxjs/toolkit";

const savedCartItems = JSON.parse(localStorage.getItem("cart"));

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    address: {},
    products: [],
    quantity: 0,
    total: 0,
  },
  reducers: {
    addProduct: (state, action) => {
      const checkIndex = state.products.findIndex(
        (x) => x._id === action.payload._id
      );
      if (checkIndex === -1) {
        state.quantity += 1;
        state.products.push(action.payload);
        state.total += action.payload.price * action.payload.duration;
      } else {
        state.quantity = state.quantity;
        state.total -=
          state.products[checkIndex].price *
          state.products[checkIndex].duration;

        state.products.splice(checkIndex, 1);
        state.products.push(action.payload);
        state.total += action.payload.price * action.payload.duration;
      }
    },
    decProduct: (state, action) => {
      const checkIndex = state.products.findIndex(
        (x) => x._id === action.payload._id
      );

      if (checkIndex > -1 && state.products[checkIndex].quantity === 1) {
        state.total -=
          state.products[checkIndex].price *
          state.products[checkIndex].duration;
        state.products.splice(checkIndex, 1);
        state.quantity -= 1;
        window.location.reload();
      } else {
        return;
      }
    },
    addAddress: (state, action) => {
      state.address = {
        address1: action.payload.address1,
        address2: action.payload.address2,
        city: action.payload.city,
        postcode: action.payload.postcode,
      };
    },

    emptyCart: (state) => {
      state.products = [];
      state.quantity = 0;
      state.total = 0;
    },
  },
});

export const { addProduct, decProduct, emptyCart, addAddress } =
  cartSlice.actions;
export default cartSlice.reducer;
